import Vue from 'vue'
import Vuex from 'vuex'
// 应用vuex插件
Vue.use(Vuex)

// 创建并暴露store
const store= new Vuex.Store({
    // 数据,相当于data
    state: {
        isLogin:false

    },
    //准备getters——用于将state中的数据进行加工
    getters: {

    },
    //准备mutations——用于操作数据（state）
    mutations: {
        setIsLogin(state,value){
            state.isLogin=value
        }
    },
    //准备actions——用于响应组件中的动作
    actions: {

    },
    modules: {}
})
export default store
