// 引入axios
import axios from "axios";
import {BASE_URL} from "../../config";

// 创建axios实例对象
let requests = axios.create({
    // 基础路径
    baseURL: BASE_URL,
    // 设置请求超时的时间
    timeout: 100000
})

// 请求拦截器：在发请求之前，请求拦截器可以检测到，可以在发出请求之前做一些事情
requests.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = token;
    }
    return config
}, (error) => {
    return Promise.reject(error);
})

// 响应拦截器
requests.interceptors.response.use(
    // 成功的回调函数：服务器响应的数据回来以后，响应拦截器可以检测到，可以做一些事情
    response => {
        return response.data
    }, error => {
        console.log(error, 'error')
    }
)

// 对外暴露
export default requests
