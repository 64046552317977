import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.css' // 引用bootstrap的样式
import 'bootstrap/dist/js/bootstrap.min.js' // 引用bootstrap的js
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import htmlToPdf from './api/htmlToPdf.js'
Vue.use(htmlToPdf)
Vue.config.productionTip = false
Vue.prototype.$isLogin = false
Vue.use(ElementUI)
Vue.filter('cdn', (value) => {
    // const domain = 'https://cdn.liangshanpin.com/'
    if (value) {
        return process.env.VUE_APP_BASE_COS_API + value
    }
    return ''
})
Vue.filter('numberFixed', (value) => {
    if (value !== '' && value !== null && value) {
        return parseFloat(value).toFixed(2)
    }
    return '0.00'
})

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
