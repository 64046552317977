/**
 * API统一管理
 */
import requests from "./request"

export const getWebsite = () => requests({
    url: 'api/website',
    method: 'post'
})

export const getGoodsList = (data) => requests({
    url: 'api/goods/list',
    method: 'post',
    data
})

export const getClassesList = () => requests({
    url: 'api/classes/list',
    method: 'post'
})

export const query = (data) => requests({
    url: 'api/order/query',
    method: 'post',
    data
})

export const saveOrder = (data) => requests({
    url: 'api/order/create',
    method: 'post',
    data
})

export const pullLock = (data) => requests({
    url: 'api/lock/pull',
    method: 'post',
    data
})
export const printData = (data) => requests({
    url: 'api/order/printData',
    method: 'post',
    data
})