/* 全局常量 */

// export const BASE_URL_IMG = "http://127.0.0.1:8881/"

// export const BASE_URL = "https://xby.zhudiantong.cn/"
// export const BASE_URL_IMG =  "https://xby.zhudiantong.cn/"

export const BASE_URL_IMG = "https://xby-1323207363.cos.sa-saopaulo.myqcloud.com/"
// export const BASE_URL_IMG = "https://cdn.bernice1115.com/"
// export const BASE_URL = "http://127.0.0.1:8000/"
export const BASE_URL = "https://api2.bernice1115.com/"
