<template>
  <div>
    <el-dialog
        style="width: 100%;"
        title="Compra（购物车）"
        :fullscreen="false"
        :visible.sync="tagVisible"
        :close-on-click-modal="true"
        @close="channel">
      <div class="cart-box">
        <div class="box-goods flex-row" v-for="(item, index) in carts" :key="index">
          <!--          <div class="flex-row-acenter goods-checkbox">-->
          <!--            <el-checkbox v-model="item.checked" @change="selectCart()"></el-checkbox>-->
          <!--          </div>-->
          <img class="goods-img" :src="baseUrlImg + item.info.master" alt="">
          <div class="goods-info flex-column">
            <div class="goods-code flex-row-acenter flex-row-jsb">
              <div class="code-item flex-row-acenter">{{ item.info.number }}</div>
              <div class="code-del pointer" @click="delCart(index)">Eliminar</div>
            </div>
            <div class="goods-title">{{ item.info.title }}</div>
            <div class="">Especificacion：{{ item.spec.title }}
              <!--              <span style="margin-left: 5px"> 编号：{{ item.spec.unit_no }}</span>-->
            </div>
            <div class="price-item flex-row-acenter flex-row-jsb flex-row-wrap">
              <div class="goods-price">
                <span class="attr">￥</span>{{ item.spec.price }}
              </div>
              <div class="flex-row-acenter">
                <div class="tag" @click="onClickNum(index, 1)">-</div>
                <input name="KeyWord" class="form-control input-btn" @change="numInput($event, index)" type="text"
                       v-model="item.spec.num_init">
                <div class="tag" @click="onClickNum(index, 2)">+</div>
              </div>
              <div>
                Subtotal：{{ setPrice(item.spec.price * item.spec.num_init) }}
              </div>

            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <div class="flex-row-jsb flex-row-acenter">
          <div class="goods-num flex-row-acenter">
            <div class="num-title">
              <span class="total-item">Total</span>
              <span>
                <span class="attr">￥</span>
                <span class="price">{{ setPrice(priceAll) }}</span>
              </span>
            </div>
          </div>
          <div class="goods-btn">
            <el-button class="btn-channel" @click="channel()">Cancelar(取消)</el-button>
            <el-button class="btn-primary" type="primary" @click="dialogOpen()">Confirmar(确认)</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <order :row-data="selectCartList"
           :checkAll="checkAll"
           :dialog-show="dialogShow"
           @cartCount="cartCountEvent"
           @dialogEvent="dialogEvent"
           @drawerEvent="drawerEvent"></order>
  </div>
</template>

<script>
import Order from "@/views/index/components/order.vue";
import {BASE_URL_IMG} from "../../../../config";

export default {
  name: "CartD",
  components: {Order},
  props: {
    drawerShow: {
      type: Boolean,
      default: true
    },
    cartList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      count: 1,
      checkAll: false,
      dialogShow: false,
      baseUrlImg: BASE_URL_IMG,
      priceAll: 0,
      selectCartList: []
    }
  },
  created() {
    this.getCartInfo()
  },
  computed: {
    tagVisible: {
      get() {
        return this.drawerShow
      },
      set(v) {
        this.$emit('drawerEvent', v)
      }
    },
    carts: {
      get() {
        return this.cartList
      }
    }
  },
  methods: {
    setPrice(price) {
      if (price > 0) {
        return price.toFixed(2)
      }
      return 0
    },
    getCartInfo() {
      // 获取购物车默认值
      let count = 0
      let priceAll = 0
      for (let i = 0; i < this.cartList.length; i++) {
        // if (this.cartList[i].checked) {
        priceAll += this.cartList[i].spec.num_init * this.cartList[i].spec.price
        count += 1
        // }
      }
      if (count === this.cartList.length && count !== 0) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
      this.priceAll = priceAll
      // console.log(priceAll)
    },
    numInput(e, index) {
      // console.log(e.target.value, index)
      if (e.target.value % this.carts[index].spec.num === 0) {
        this.carts[index].spec.num_init = e.target.value
        localStorage.setItem('cart', JSON.stringify(this.carts))
      } else {
        this.carts[index].spec.num_init = this.carts[index].spec.num
        this.$message.error('购买数量必须为' + this.carts[index].spec.num + '的整数倍数')
      }
    },
    onClickNum(index, type) {
      const init = parseInt(this.carts[index].spec.num_init);
      const num = parseInt(this.carts[index].spec.num)

      if (type === 1) {
        if (init <= num) {
          let msg = '最小数量不能小于' + num
          this.$confirm(msg, '提示', {
            confirmButtonText: '知道了',
            showCancelButton: false,
            type: 'warning'
          }).then(() => {
          }).catch(() => {
          })
          return
        }
        this.carts[index].spec.num_init = init - num;
      } else {
        this.carts[index].spec.num_init = init + num;
      }
      localStorage.setItem('cart', JSON.stringify(this.carts))
      this.selectCart()
    },
    channel() {
      this.$emit('drawerEvent')
    },
    dialogOpen() {
      if (JSON.parse(localStorage.getItem('cart')).length === 0) {
        this.$confirm('购物车为空, 请先将商品加入购物车!', '提示', {
          confirmButtonText: '知道了',
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
        }).catch(() => {
        })
        return
      }
      for (let i = 0; i < this.carts.length; i++) {
        if (this.carts[i].spec.num_init === '' || this.carts[i].spec.num_init <= 0) {
          this.$message.error(this.carts[i].info.title + '数量必须大于0')
          return;
        }
      }
      // 解决第一次打开购物车读取缓存信息, 但是selectCartList 数据为空
      this.selectCart()

      // if (this.selectCartList.length === 0) {
      //   this.$confirm('请先选中商品!', '提示', {
      //     confirmButtonText: '知道了',
      //     showCancelButton: false,
      //     type: 'warning'
      //   }).then(() => {
      //   }).catch(() => {
      //   })
      //   return
      // }
      this.dialogShow = true
    },
    cartCountEvent(v) {
      this.$emit('cartCount', v)
    },
    dialogEvent() {
      this.dialogShow = false
    },
    drawerEvent() {
      this.$emit('drawerEvent')
    },
    delCart(index) {
      this.carts.splice(index, 1)
      localStorage.setItem('cart', JSON.stringify(this.carts))
      const cartCount = JSON.parse(localStorage.getItem('cart')).length
      this.$emit('resetCart', cartCount)

      this.getCartInfo()
    },
    selectCart() {
      let selectCartList = []
      let count = 0
      let priceAll = 0
      for (let i = 0; i < this.carts.length; i++) {
        if (this.carts[i].spec.num_init === '' || this.carts[i].spec.num_init <= 0) {
          this.$message.error(this.carts[i].info.title + '数量必须大于0')
          break;
        }
        priceAll += this.carts[i].spec.num_init * this.carts[i].spec.price
        selectCartList.push({
          unit: this.carts[i].spec.title,
          price: this.carts[i].spec.price,
          num: this.carts[i].spec.num_init,
          unit_no: this.carts[i].spec.unit_no,
          title: this.carts[i].info.title,
          url: this.carts[i].info.master,
          number: this.carts[i].info.number
        })
        count += 1
      }
      if (count === this.carts.length) {
        this.checkAll = true
      } else {
        this.checkAll = false
      }
      this.priceAll = priceAll
      this.selectCartList = selectCartList
      // console.log(this.carts, '单个选中')
      this.setCart(this.carts)
    },
    selectCartAll() {
      if (!this.checkAll) {
        this.priceAll = 0
        this.selectCartList = []
        this.carts.forEach((item) => {
          item.checked = false
        })
      } else {
        let selectCartList = []
        this.carts.forEach((item) => {
          item.checked = true
          this.priceAll += item.spec.num_init * item.spec.price
          selectCartList.push({
            unit: item.spec.title,
            price: item.spec.price,
            num: item.spec.num_init,
            unit_no: item.spec.unit_no,
            title: item.info.title,
            url: item.info.master,
          })
        })
        this.selectCartList = selectCartList
      }
      // console.log(this.selectCartList, '提交数据')
      this.setCart(this.carts)
      // console.log(this.carts, '全部选中')
    },
    setCart(cart) {
      localStorage.setItem('cart', JSON.stringify(cart))
      // console.log(JSON.parse(localStorage.getItem('cart')), '购物车')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "cart";

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.newCheckSty .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #337ab7;
  background-color: #337ab7;
}

::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #337ab7;
}
</style>
