import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export default {
  install(Vue) {
    Vue.prototype.htmlToPdf = function (name, title, count) {
      html2Canvas(document.querySelector('#' + name), {
        // allowTaint: true,
        useCORS: true,
        scale: 2, // 提升画面质量，但是会增加文件大小
        dpi: window.devicePixelRatio * 1,
      }).then((canvas) => {
        console.log('canvas', canvas);
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        // const contentWidth = 714
        // const contentHeight = 250

        /* 导出不分页处理 */
        const pageData = canvas.toDataURL('image/jpeg', 1.0)

        const pdfWidth = (contentWidth + 10) / 2 * 0.75
        const pdfHeight = (contentHeight + 500) / 2 * 0.75 // 500为底部留白

        const imgWidth = pdfWidth
        const imgHeight = (contentHeight / 2 * 0.75) // 内容图片这里不需要留白的距离
        let PDF
        if (count > 4) {
          PDF = new JsPDF('', 'pt', [pdfWidth + 50, pdfHeight + 100])
        } else {
          PDF = new JsPDF('p', 'pt', 'a4')
        }
        PDF.addImage(pageData, 'jpeg', 33, 33, imgWidth, imgHeight)
        PDF.save(title + '.pdf')
      })
    }
  }
}
